'use client'

import { useState } from 'react'
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  ArrowPathIcon,
  CheckIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'
import logo from './sp-logo-white.png';



const SignUpComponent = () => {
    return (
        <>
          {/*
            This example requires updating your template:
    
            ```
            <html class="h-full bg-white">
            <body class="h-full">
            ```
          */}
          
            <div className="w-full max-w-sm space-y-10">
              <div>
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Skryf in vir die waglys
                </h2>
              </div>
              <div>
              <a
                href="https://forms.zoho.com/studenteplein/form/PleinWerfAansoekvorm1"
                target="_blank" // This will open the link in a new tab
                rel="noopener noreferrer" // This improves security by preventing the new page from accessing your window object
                className="flex w-full justify-center rounded-md bg-brand-orange px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-brand-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
              Skryf in
              </a>
                </div>
    
            
            </div>
        
        </>
      )
}

export default SignUpComponent;
