  'use client'

import { useState } from 'react'
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  IdentificationIcon,
  UserGroupIcon,
  TrophyIcon,
  ChatBubbleLeftIcon
} from '@heroicons/react/20/solid'
import logo from './sp-logo-white.png';


const ProductInfoBusinesses = () => {

    const stats = [
        { label: 'Founded', value: '2021' },
        { label: 'Employees', value: '37' },
        { label: 'Countries', value: '12' },
        { label: 'Raised', value: '$25M' },
      ]

   
  return (  
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-4">
            <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
              <img
                alt=""
                src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
              />
              <div className="absolute inset-0 bg-gray-900 mix-blend-color opacity-5" />
              <div
                aria-hidden="true"
                className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#233843] to-[#E3834B] opacity-20"
                />
              </div>
              <figure className="relative isolate">
                <svg
                  fill="none"
                  viewBox="0 0 162 128"
                  aria-hidden="true"
                  className="absolute -left-2 -top-4 -z-10 h-32 stroke-white/20"
                >
                  <path
                    d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                    id="0ef284b8-28c2-426e-9442-8655d393522e"
                  />
                  <use x={86} href="#0ef284b8-28c2-426e-9442-8655d393522e" />
                </svg>
                
                <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                  <p>
                  'n Goeie werkskultuur is 'n voorvereiste vir besigheidsukses. PleinWerf is gunstig geposisioneer om top kandidate met die hand te kies uit in 'n
                  studentenetwerk van afgeronde, kultureel-ingestelde studente. 
                  </p>
                </blockquote>
              
              </figure>
            </div>
          </div>
          <div>
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-brand-orange">Werkgewers</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Kandidate met die hand gekies vir jou besigheid
              </h1>
              <div className="max-w-xl">
                <p className="mt-6">
                 Dit is deesdae moeilik om 'n besigheid te groei met 'n span wat nie net waarde toevoeg nie maar ook 'n kulturele aanvoeling het.
                 'n Goeie werkskultuur is 'n voorvereiste vir besigheidsukses. PleinWerf is gunstig geposisioneer om top kandidate met die hand te kies uit in 'n
                  studentenetwerk van afgeronde, kultureel-ingestelde studente. 
                </p>
                <p className="mt-6">
                 PleinWerf-kandidate vaar nie net akademies uitstekend in hul onderskeie akademiese velde nie, maar het 'n bewese rekord van deelname aan hul 
                 taal- en kultuurgemeenskappe op kampus en is volronde persone wat tot 'n gunstige werkskultuur sal kan bydra. 
                </p>
                <p className="mt-6">
                 PleinWerf-kandidate word vooraf gekeur en aan uitgesoekte besighede aanbeveel op grond van:
                </p>
                <p className="mt-8">
                <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                    <TrophyIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-brand-orange" />
                    <span>
                      <strong className="font-semibold text-gray-900">Akademiese prestasie.</strong> PleinWerf ondersteun aanstellings op grond van akademiese meriete en kandidate behaal goeie akademiese uitslae.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <IdentificationIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-brand-orange" />
                    <span>
                      <strong className="font-semibold text-gray-900">Psigometriese profiel.</strong> PleinWerf onderneem psigometriese toetse van kandidate, pasgemaak vir die industrie waarin hulle wil inbeweeg.                   </span>
                  </li>
                  <li className="flex gap-x-3">
                    <UserGroupIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-brand-orange" />
                    <span>
                      <strong className="font-semibold text-gray-900">Kulturele aanpasbaarheid.</strong> PleinWerf sal kandidate aanbeveel wat 'n kulturele passing in jou besigheid se konteks sal wees.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <ChatBubbleLeftIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-brand-orange" />
                    <span>
                      <strong className="font-semibold text-gray-900">Afrikaanse deelname.</strong> PleinWerf ondersteun kandidate wat op hul kampus deelneem aan en bydrae tot Afrikaanse akademiese, intellektuele en sosiale ruimtes.
                    </span>
                  </li>
                </ul>
                </p>
                <p className="mt-8">
                  PleinWerf is tans in 'n loodsfase. Besighede wat daarin belangstel om aan die loodsfase deel te neem kan hier besonderhede vermeld: 
                </p>
              </div>
            </div>

            <div className="mt-10 flex">
              <a href="#" className="text-base font-semibold leading-7 text-brand-orange">
                Neem deel aan die loodsfase <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>  

  );
}

export default ProductInfoBusinesses;

  
