'use client'

import React, { useState, useRef } from 'react';
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  ArrowPathIcon,
  CheckIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'

import Hero from './Hero'
import ProductInfo from './ProductInfo'
import FeatureSection from './FeatureSection'
import ProductInfoBusinesses from './ProductInfoBusinesses'
import Footer from './Footer'
import Header from './Header'


const navigation = [
  { name: 'Die Diens', href: '#' },
  { name: 'Word deel van PleinWerf', href: '#' }

]

const tiers = [
  {
    name: 'Hobby',
    id: 'tier-hobby',
    href: '#',
    priceMonthly: '$19',
    description: "The perfect plan if you're just getting started with our product.",
    features: ['25 products', 'Up to 10,000 subscribers', 'Advanced analytics', '24-hour support response time'],
    featured: false,
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: '$49',
    description: 'Dedicated support and infrastructure for your company.',
    features: [
      'Unlimited products',
      'Unlimited subscribers',
      'Advanced analytics',
      'Dedicated support representative',
      'Marketing automations',
      'Custom integrations',
    ],
    featured: true,
  },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Parent() {
  const [optionSelected, setOptionSelected] = useState<string>('');
  // Specify that the refs are possibly null HTMLDivElement types
  const productInfoRef = useRef<HTMLDivElement>(null);
  const productInfoBusinessesRef = useRef<HTMLDivElement>(null);

  const handleOptionChange = (optionSelected: string) => {
    setOptionSelected(optionSelected);
    // Scroll to the relevant component based on the option selected
    if (optionSelected === 'students' && productInfoRef.current) {
      productInfoRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (optionSelected === 'businesses' && productInfoBusinessesRef.current) {
      productInfoBusinessesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

   return (
    <div className="bg-white">
        <header>
      <Header />
    </header>
      <main>
        <Hero 
          title="Waar Afrikaanse talent en werkgewers ontmoet"
          subTitle="PleinWerf bring hoë-gehalte studente en Afrikaans-vriendelike werkgewers bymekaar."
          setOptionSelected={handleOptionChange}
        />
        <div ref={productInfoRef}>
          {optionSelected === 'students' && <ProductInfo />}
        </div>
        <div ref={productInfoBusinessesRef}>
          {optionSelected === 'businesses' && <ProductInfoBusinesses />}
        </div>
      </main>
      <Footer />
    </div>
  );

};