'use client'

import { useState } from 'react'
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, MinusSmallIcon, PlusSmallIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  IdentificationIcon,
  UserGroupIcon,
  TrophyIcon,
  ChatBubbleLeftIcon
} from '@heroicons/react/20/solid'
import logo from './sp-logo-white.png';
import SignUpComponent from './SignUp'



const ProductInfo = () => {
  const faqs = [
    {
      question: "Moet ek in Afrikaans swot om in aanmerking te kom?",
      answer:
        "Nee, dit is nie 'n vereiste nie.",
    },
    {
      question: "Wat kos dit my om op die waglys te kom?",
      answer:
        "Dit is gratis vir lede van StudentePlein.",
    },

  ]
    return (
      
        <div className="relative isolate overflow-hidden bg-white py-10 sm:py-32">
          <div
            aria-hidden="true"
            className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          >
            <div
              style={{
                clipPath:
                  'polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)',
              }}
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#e4e6e7] to-[#E3834B] opacity-50"
            />
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <p className="text-lg font-semibold leading-8 tracking-tight text-brand-orange">Studente</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Kom in aanmerking vir werksgeleenthede in jou veld</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                PleinWerf is 'n platform vir werksgeleenthede vir Afrikaanse studente. Verhoog jou kanse om in aanmerking te kom vir werk in PleinWerf se netwerk deur nou in te skryf vir die waglys.
              </p>
              
            </div>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
              <div className="relative lg:order-last lg:col-span-5">
                <svg
                  aria-hidden="true"
                  className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                >
                  <defs>
                    <pattern
                      id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                      width={200}
                      height={200}
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M0.5 0V200M200 0.5L0 0.499983" />
                    </pattern>
                  </defs>
                  <rect fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" width="100%" height="100%" strokeWidth={0} />
                </svg>
                <figure className="border-l border-brand-blue pl-8">
                <SignUpComponent />
                </figure>
              </div>
              <div className="max-w-xl text-base leading-7 text-gray-700 lg:col-span-7">
                <p>
                Om werk te kry in jou veld, as deel van ’n reuse poel studente landswyd wat werk soek, is ’n groot uitdaging. Studente wat boonop ingestel is oor die werkskultuur waar hulle wil inskakel, ervaar selfs ’n groter uitdaging.
                PleinWerf verstaan hierdie uitdagings. Ons wil jou bystaan om in te skakel by ’n werksomgewing wat op soek is na jou spesifieke vaardighede en te vinde is vir jou voorkeure oor werkskultuur. 
                Faktore wat belangrik sal wees vir werkgewers in ons netwerk is:
                </p>
                <ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                    <TrophyIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-brand-orange" />
                    <span>
                      <strong className="font-semibold text-gray-900">Akademiese prestasie.</strong> Enige werksplek slaan hoofsaaklik ag op jou akademiese prestasie. PleinWerf ondersteun aanstellings op grond van akademiese meriete.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <IdentificationIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-brand-orange" />
                    <span>
                      <strong className="font-semibold text-gray-900">Psigometriese profiel.</strong> Die meeste werksplekke vereis dat jy psigometriese profilering ondergaan om te bepaal of jy oor die persoonlikheid en vaardighede beskik om 'n spesifieke werk te doen. PleinWerf sal jou bystaan in hierdie proses.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <UserGroupIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-brand-orange" />
                    <span>
                      <strong className="font-semibold text-gray-900">Kulturele aanpasbaarheid.</strong> Werkgewers in PleinWerf se netwerk stel belang in jou vermoë om by hul werksplekkultuur in te pas. PleinWerf sal jou kulturele voorkeure en aanpasbaarheid bepaal as deel van die proses om jou onder moontlike werkgewers se aandag te bring.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <ChatBubbleLeftIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-brand-orange" />
                    <span>
                      <strong className="font-semibold text-gray-900">Afrikaanse deelname.</strong> Afrikaans-vriendelike besighede in ons netwerk stel belang in jou vermoë om in Afrikaans te kan dink en by te dra. PleinWerf sal 'n profiel opstel gebaseer op jou Afrikaanse vermoëns.
                    </span>
                  </li>
                </ul>
                <p className="mt-8">
                  Dit is maklik om in aanmerking te kom vir werksplasing deur middel van PleinWerf. Vul die vorm in om jouself op die waglys te plaas. Ons sal dan na jou uitreik vir meer inligting.
                </p>
              </div>
            </div>
          </div>

          <div className="mx-auto mt-10 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-8xl divide-y divide-gray-900/10">
            <h2 className="text-2xl font-bold leading-5 tracking-tight text-gray-900">Algemene vrae en antwoorde</h2>
            <dl className="mt-10 space-y-1 divide-y divide-gray-900/10">
              {faqs.map((faq) => (
                <Disclosure key={faq.question} as="div" className="pt-6">
                  <dt>
                    <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                      <span className="text-base font-semibold leading-7">{faq.question}</span>
                      <span className="ml-6 flex h-7 items-center">
                        <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                        <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                      </span>
                    </DisclosureButton>
                  </dt>
                  <DisclosurePanel as="dd" className="mt-2 pr-12">
                    <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                  </DisclosurePanel>
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
        </div>
      )
}

export default ProductInfo;
